import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { User } from "../../reducers/auth";
import * as actions from "../../actions";
import { useDispatch } from "react-redux";
import { Row } from "antd";

interface Props {
  // component: any;
  component: any;
  user?: User;
  exact?: boolean;
  path?: string;
}

const PrivateRoute: React.FC<Props> = (props) => {
  const { component: Component, user, ...rest } = props;
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    const alreadyFetched = sessionStorage.getItem("auth_fetched");
    if (auth && !alreadyFetched) {
      const { access_token, refresh_token } = JSON.parse(auth!);
      const from = localStorage.getItem('from') || '/';
      dispatch(actions.getSessionToken(actions.loginWithToken(access_token, refresh_token, from)));
      sessionStorage.setItem("auth_fetched", "true");
    } else {
      if (alreadyFetched) {
        // Show the loading while waiting for user. If user is found early the loading is disabled by the second useEffect.
        setTimeout(() => { setIsLoading(false); }, 5000);
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      setIsLoading(false);
      sessionStorage.removeItem("auth_fetched");
    }
  }, [user]);

  if (isLoading) {
    return (
      <Row align="middle" justify="center">
        <h3>Loading...</h3>
      </Row>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
